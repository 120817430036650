import React from "react";

import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Box } from "../Core";
import Logo from "../Logo";
import { Link } from "gatsby";

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    display: inline-block;
    margin-top: 10px;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const Footer = ({ isDark = false, pageContext }) => {
  const { language, intl } = pageContext;
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 20px;
            `}
          >
            <Row className="justify-content-center">
              <Col lg="2" md="4">
                <Logo
                  language={language}
                  className="logo_footer"
                  white={isDark}
                />
              </Col>
              <Col lg="10" md="8" className="mt-5 mt-lg-0">
                <UlStyled
                  color={isDark ? "lightShade" : "darkShade"}
                  className="text-right"
                >
                  <li className="mr-3">
                    <Link to={`/${language}/about`}>{intl.messages.about}</Link>
                  </li>
                  <li className="mr-3">
                    <Link to={`/${language}/career`}>{intl.messages.jobs}</Link>
                  </li>
                  <li className="mr-3">
                    <Link to={`/${language}/blogs`}>{intl.messages.blogs}</Link>
                  </li>
                </UlStyled>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>
                  &copy; {new Date().getFullYear()} {intl.messages.copywrite}
                </p>
              </Col>
              <Col sm="6" className="text-sm-right text-center">
                <ul className="social-icons">
                  <li>
                    <Link to="/#">
                      <i className="icon icon-logo-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#">
                      <i className="icon icon-logo-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#">
                      <i className="icon icon-logo-facebook"></i>
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
