import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { ListGroup, Collapse } from 'react-bootstrap'
import { FaAngleRight, FaAngleDown } from 'react-icons/fa'
import { Link } from 'gatsby'
import GlobalContext from '../../context/GlobalContext'

const NestedMenuContainer = styled.div`
  a {
    color: ${({ theme }) => theme.colors.text};
    transition: all 0.3s ease-out;
    &:hover,
    &:active {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: none;
    }
  }

  .list-group-item {
    & + .collapse:not(.show) {
      .list-group-item {
        border: none !important;
        border-width: 0 !important;
      }
    }
    & + .collapse.show {
      .list-group-item {
        &:first-child {
          border-top: none !important;
        }
      }
    }
  }
  .collapse + .list-group-item {
    border-top-width: 0;
  }
  /* .list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 1px;
  } */
`

// const defaultMenuItems = [
//   { name: "home", label: { ar: "الرئيسية", en: "Home" } },
//   {
//     name: "billing",
//     label: { ar: "الدفع", en: "Billing" },
//     items: [
//       { name: "statements", label: { ar: "الصياغات", en: "Statements" } },
//       { name: "reports", label: { ar: "التقارير", en: "Reports" } },
//     ],
//   },
//   {
//     name: "settings",
//     label: { ar: "الإعدادات", en: "Settings" },
//     items: [
//       { name: "profile", label: { ar: "صفحة شهصية", en: "Profile" } },
//       { name: "insurance", label: { ar: "التامين", en: "Insurance" } },
//       {
//         name: "notifications",
//         label: { ar: "التنبيهات", en: "Notifications" },
//         items: [
//           { name: "email", label: { ar: "الإيميل", en: "Email" } },
//           {
//             name: "desktop",
//             label: { ar: "سطح المكتب", en: "Desktop" },
//             items: [
//               { name: "schedule", label: { ar: "جدولة", en: "Schedule" } },
//               { name: "frequency", label: { ar: "متكررة", en: "Frequency" } },
//             ],
//           },
//           { name: "sms", label: { ar: "رسائل نصية", en: "SMS" } },
//         ],
//       },
//     ],
//   },
// ];

const MenuItem = ({
  label,
  isExternal = false,
  name,
  items,
  depthStep = 20,
  depth = 0,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const hasSubItems = Array.isArray(items)

  const gContext = useContext(GlobalContext)

  return (
    <>
      {hasSubItems ? (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
            cursor: pointer;
          `}
          onClick={() => setOpen(!open)}
          className="d-flex align-items-center justify-content-between"
        >
          <span>{label['ar']}</span>
          <span>{open ? <FaAngleDown /> : <FaAngleRight />}</span>
        </ListGroup.Item>
      ) : (
        <ListGroup.Item
          {...rest}
          css={`
            padding-left: ${depth * depthStep}px !important;
          `}
        >
          {isExternal ? (
            <Link
              to={`${name}`}
              onClick={() => {
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas()
                }
              }}
            >
              {label['ar']}
            </Link>
          ) : (
            <Link
              to={`/${name}`}
              onClick={() => {
                if (gContext.visibleOffCanvas) {
                  gContext.toggleOffCanvas()
                }
              }}
            >
              {label['ar']}
            </Link>
          )}
        </ListGroup.Item>
      )}

      {hasSubItems ? (
        <Collapse in={open}>
          <ListGroup>
            {items.map(subItem => (
              <MenuItem
                key={subItem.name}
                depth={depth + 1}
                depthStep={depthStep}
                {...subItem}
              />
            ))}
          </ListGroup>
        </Collapse>
      ) : null}
    </>
  )
}

const NestedMenu = ({ menuItems }) => {
  return (
    <NestedMenuContainer>
      <ListGroup variant="flush">
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={`${menuItem.name}${index}`}
            depthStep={20}
            depth={0}
            {...menuItem}
          />
        ))}
      </ListGroup>
    </NestedMenuContainer>
  )
}

export default NestedMenu
