export const menuItems = [
  { name: 'about', label: { ar: 'عن مفتاح', en: 'About' } },
  { name: 'blogs', label: { ar: 'المدونة', en: 'Blogs' } },
  {
    name: 'signin',
    label: { ar: 'تسجيل دخول', en: 'Sign in' },
  },

  // {
  //   name: "pages",
  //   label: { ar: "صفحات", en: "Pages" },
  //   items: [
  //     { name: "about", label: { ar: "عن", en: "About" } },
  //     {
  //       name: "career",
  //       label: { ar: "الوظائف", en: "Career" },
  //       items: [
  //         { name: "career", label: { ar: "قائمة الوظائف", en: "Career List" } },
  //         {
  //           name: "career-details",
  //           label: { ar: "تفاصيل الوظيفة", en: "Career Details" },
  //         },
  //       ],
  //     },
  //     {
  //       name: "case-study",
  //       label: { ar: "دراسة حالة", en: "Case Study" },
  //       items: [
  //         { name: "case-study", label: { ar: "دراسة حالة", en: "Case Study" } },
  //         {
  //           name: "case-study-details",
  //           label: { ar: "تفاصيل دراسة حالة", en: "Case Study Details" },
  //         },
  //       ],
  //     },
  //     {
  //       name: "blog",
  //       label: { ar: "المدونة", en: "Blog" },
  //       items: [
  //         {
  //           name: "blog-regular",
  //           label: { ar: "المدونة", en: "Blog Regular" },
  //         },
  //         {
  //           name: "blog-left-sidebar",
  //           label: { ar: "التدوينة مع عمود يسار", en: "Blog Left Sidebar" },
  //         },
  //         {
  //           name: "blog-right-sidebar",
  //           label: { ar: "التدوينة مع عمود يمين", en: "Blog Right Sidebar" },
  //         },
  //         {
  //           name: "blog-details",
  //           label: { ar: "تفاصيل التدونية", en: "Blog Details" },
  //         },
  //       ],
  //     },
  //     {
  //       name: "contact",
  //       label: { ar: "تواصل معنا", en: "Contact" },
  //       items: [
  //         {
  //           name: "contact-1",
  //           label: { ar: "تواصل معنا 01", en: "Contact 01" },
  //         },
  //         {
  //           name: "contact-2",
  //           label: { ar: "تواصل معنا 02", en: "Contact 02" },
  //         },
  //         {
  //           name: "contact-3",
  //           label: { ar: "تواصل معنا 03", en: "Contact 03" },
  //         },
  //       ],
  //     },
  //     {
  //       name: "account",
  //       label: { ar: "الحساب", en: "Account" },
  //       items: [
  //         { name: "signin", label: { ar: "تسجيل جديد", en: "Signin" } },
  //         {
  //           name: "signin-bg",
  //           label: { ar: "تسجيل دخول BG", en: "Signin BG" },
  //         },
  //         { name: "signup", label: { ar: "تسجيل جديد", en: "Signup" } },
  //         {
  //           name: "signup-bg",
  //           label: { ar: "تسجيل جديد BG", en: "Signup BG" },
  //         },
  //         {
  //           name: "reset-pass",
  //           label: { ar: "تعيين كلمة المرور", en: "Reset Password" },
  //         },
  //         {
  //           name: "reset-pass-bg",
  //           label: { ar: "تعيين كلمة مرور BG", en: "Reset Password BG" },
  //         },
  //       ],
  //     },
  //     {
  //       name: "pricing",
  //       label: { ar: "التسعير", en: "Pricing" },
  //       items: [
  //         { name: "pricing-1", label: { ar: "التسعير 01", en: "Pricing 01" } },
  //         { name: "pricing-2", label: { ar: "التسعير 02", en: "Pricing 02" } },
  //       ],
  //     },
  //     {
  //       name: "utility",
  //       label: { ar: "خدمات", en: "Utility" },
  //       items: [
  //         {
  //           name: "coming-soon",
  //           label: { ar: "نراكم قريباً", en: "Coming Soon" },
  //         },
  //         {
  //           name: "coming-soon-2",
  //           label: { ar: "نراكم قريباً 02", en: "Coming Soon 02" },
  //         },
  //         { name: "404", label: { ar: "صفحة 404", en: "404 Page" } },
  //         { name: "thank-you", label: { ar: "شكراً لك", en: "Thank You" } },
  //         {
  //           name: "terms-conditions",
  //           label: { ar: "الشروط والأحكام", en: "Terms & Conditions" },
  //         },
  //       ],
  //     },
  //     {
  //       name: "essential",
  //       label: { ar: "روابط اضافية", en: "Essential" },
  //       items: [
  //         { name: "faq", label: { ar: " الاسئلة الشائعة", en: "FAQ" } },
  //         { name: "reviews", label: { ar: "التقيمات", en: "Reviews" } },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: 'design',
  //   label: { ar: 'صمم', en: 'Design' },
  // },
  // {
  //   name: "https://support/",
  //   label: { ar: "الدعم", en: "Support" },
  //   isExternal: true,
  // },
]
