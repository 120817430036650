import React from "react";
//import { Link  } from "gatsby-plugin-intl";
import { Link } from "gatsby";
import imgL1Logo from "../../assets/image/svg/logo-dark.svg";
import imgL1LogoWhite from "../../assets/image/svg/logo-white.svg";

const Logo = ({ language, white, height, className = "", ...rest }) => {
  return (
    <Link
      to={`${"/" + language}`}
      className={`${className}`}
      {...rest}
    >
      {white ? (
        <img src={imgL1LogoWhite} alt="" />
      ) : (
        <img src={imgL1Logo} alt="" />
      )}
    </Link>
  );
};

export default Logo;
