import React from "react";
import { Location } from "@reach/router";
import { changeLocale } from "gatsby-plugin-intl";

// Provably somewhere else and imported in the `LanguageSwitcher` component
const supportedLanguages = [
  {
    label: `English`,
    languageTag: `en`,
  },
  {
    label: `العربية`,
    languageTag: `ar`,
  },
];

const LanguageSwitcher = ({ intl }) => {
  // Create a string to allow a regex replacement for SEO hreflang links: https://support.google.com/webmasters/answer/189077
  return supportedLanguages.map((language, index) => {
    return (
      <Location key={index}>
        {({ location }) => (
          <li className="drop-menu-item">
            <a
              href={`${"#"}`}
              onClick={() => changeLocale(language.languageTag)}
              style={{
                cursor: `pointer`,
              }}
            >
              {language.label}
            </a>
          </li>
        )}
      </Location>
    );
  });
};

export default LanguageSwitcher;
